import React from 'react'
import Layout from '../components/layout'
import { navigate } from 'gatsby'
import { OutlineButton } from '@trexity/common-client/lib/components'

export default function Developers () {
  return (
    <Layout>
      <section>
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className="inner" style={{ width: '100%' }}>
              <h1>404</h1>
              <p>Shhh. Just don’t look at it.</p>
              <OutlineButton
                color="#6096BA"
                disableAnimations
                onClick={() => navigate('/')}
              >Home</OutlineButton>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
